import { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import ErrorPage from 'next/error';
import { GetServerSideProps, GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { PageEntityResponseCollection } from 'types/cms';
import Seo from '@/components/Seo';
import { GET_PAGE, GET_PAGE_PATHS } from '../cms/queries';
import { apolloCon } from '../cms';
import BlockManager from '../components/BlocksManager';
import { ApolloError } from '@apollo/client';

type RootPages = {
  pages: PageEntityResponseCollection;
};

export default function HomePage({ pages }: RootPages) {
  const { data: session } = useSession();

  useEffect(() => {}, [session]);
  const router = useRouter();

  // Check if the required data was provided
  if (!router.isFallback && !pages?.data.length) {
    return <ErrorPage statusCode={404} />;
  }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>;
  }

  return (
    <>
      <Seo metadata={pages?.data[0].attributes?.meta} />
      <BlockManager pageInfo={pages?.data[0].attributes} blocks={pages?.data[0].attributes?.body} />
    </>
  );
}

export const getStaticPaths: GetStaticPaths = async () => {
  const { data } = await apolloCon.query({
    query: GET_PAGE_PATHS,
    variables: {
      filters: {
        slug: {
          notNull: true,
        },
      },
    },
  });

  const paths = data.pages.data.map((page: any) => {
    const { slug } = page.attributes;
    const slugArray = !slug ? [] : slug.split('/');
    return {
      params: { slug: slugArray },
    };
  });

  return { paths, fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { params } = context;

  try {
    const { data, errors, error } = await apolloCon.query({
      query: GET_PAGE,
      variables: {
        lbDonationFilter: {
          payment_status: {
            eq: 'succeeded',
          },
        },
        donateOptionsFilters2: {
          active: {
            eq: true,
          },
        },
        filters: {
          slug: {
            eq: (params!.slug! as string[]).join('/'), //in cms Pages, slug could be multi level deep about-us/company
          },
        },
      },
    });

    if (data.pages.data[0] == null) {
      return { props: {} }; // Giving the page no props will trigger a 404 page
    }
    if (errors && errors.length > 0) {
      console.error('GraphQL errors:', errors);
      return {
        notFound: true,
      };
    }

    return {
      props: {
        pages: data.pages,
      },
      revalidate: 10,
    };
  } catch (error) {
    if (error instanceof ApolloError) {
      console.error('ApolloError:', error.message);
      if (error.networkError) {
        console.error('Network error:', error.networkError);
      }
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      }
    } else {
      console.error('Unexpected error:', error);
    }

    return {
      notFound: true,
    };
  }
};
